import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import {
  Container,
  Vertical,
  Title,
  PlanContainer,
  Price,
  Checked,
  SubscribeContainer,
  Description,
  Item,
  DiscountedPrice,
} from "./style";
import { planPurchasesClient } from "../../api";
import LoadingPage from "../loading_page";
import { useStores } from "../../contexts/store_context";
import { IComponentProps } from "./types";
import event from "../../event";

// export const PRICE = 12.99;
export const PRICE = 12.99;

const BillingPage: React.FC<IComponentProps> = ({ t }: IComponentProps) => {
  const stores = useStores();

  React.useEffect(() => {
    stores?.billingPageStore.load();
    event.track("billing_page.view");
  }, [stores]);

  const company = stores?.billingPageStore.company;

  if (!company) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <Vertical>
        <Title>{t("billing-page.title")}</Title>
        <Description>{t("billing-page.description")}</Description>
      </Vertical>
      <PlanContainer>
        <Title>{t("profile-page.free.title")}</Title>
        <Description>{t("profile-page.free.description")}</Description>

        <Item>{t("plan.digital-menu")}</Item>
        <Item>{t("plan.products")}</Item>

        <Price></Price>

        {company.hasActivePro == false && (
          <SubscribeContainer>
            <Checked />
            {t("active")}
          </SubscribeContainer>
        )}
      </PlanContainer>
      <PlanContainer>
        <Title highlight>{t("billing-page.pro.title")}</Title>
        <Description>{t("profile-page.pro.description")}</Description>

        <ul>
          <Item>{t("plan.video")}</Item>
          {/* <Item>{t("plan.dashboard")}</Item> */}
          {/* <Item>{t("plan.orders")}</Item>
          <Item>{t("plan.tables")}</Item>
          <Item>{t("plan.ledgers")}</Item> */}
        </ul>

        <Price>$ 25.98</Price>
        <p>{t("billing-page.pro.discounted-price")}</p>
        <DiscountedPrice>$ {PRICE}</DiscountedPrice>

        {company.hasActivePro === false && (
          <>
            <PayPalButton
              amount={PRICE}
              shippingPreference="NO_SHIPPING"
              onApprove={() => {
                event.track("billing_page.payapl_button.approve");
              }}
              onError={(error: any) => {
                console.error("error", error);
                event.track("billing_page.payapl_button.fail");
              }}
              onSuccess={async (details: any, data: any) => {
                await planPurchasesClient.create(data.orderID);

                window.location.reload();
              }}
              options={{
                clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
              }}
            />
          </>
        )}
        {company.hasActivePro == true && (
          <SubscribeContainer>
            <>
              <Checked />
              {t("active")}
            </>
          </SubscribeContainer>
        )}
      </PlanContainer>
    </Container>
  );
};

export default BillingPage;
