import { AxiosInstance } from "axios";
import Cookies from "../lib/cookies";

interface ICompany {
  email: string;
  id: number;
  name: string;
  description: string;
}

interface ISignInResposne {
  data: ICompany;
}

interface ISignUpResposne {
  status: string;
  data: ICompany;
}

interface ISignOutResponse {
  success: boolean;
}

interface IValidateTokenResponse {
  success: boolean;
  data: {
    email: string;
    name: string;
  };
}

class AuthClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async sign_in(
    email: string,
    password: string,
    is_employee = false,
  ): Promise<ISignInResposne> {
    const namespace = is_employee ? "employees" : "companies";
    const response = await this.restClient.post(
      `/api/v1/${namespace}/auth/sign_in`,
      {
        email,
        password,
      },
    );

    console.log(response);

    // Any status code that lie within the range of 2xx cause this function to trigger
    Cookies.set("is_employee", is_employee ? "true" : "false");
    Cookies.set("access-token", response.headers["access-token"], {
      expires: 3,
    });
    Cookies.set("token-type", response.headers["token-type"], {
      expires: 3,
    });
    Cookies.set("client", response.headers.client, {
      expires: 3,
    });
    Cookies.set("expiry", response.headers.expiry, {
      expires: 3,
    });
    Cookies.set("uid", response.headers.uid, {
      expires: 3,
    });

    return response.data;
  }

  async sign_out(is_employee = false): Promise<ISignOutResponse> {
    const namespace = is_employee ? "employees" : "companies";
    const response = await this.restClient.delete(
      `/api/v1/${namespace}/auth/sign_out`,
    );

    Cookies.remove("is_employee");
    Cookies.remove("access-token");
    Cookies.remove("token-type");
    Cookies.remove("client");
    Cookies.remove("uid");
    Cookies.remove("expiry");

    return response.data;
  }

  async validate_token(
    uid: string,
    client: string,
    accessToken: string,
    is_employee = false,
  ): Promise<IValidateTokenResponse> {
    const namespace = is_employee ? "employees" : "companies";
    const response = await this.restClient.get(
      `/api/v1/${namespace}/auth/validate_token`,
      {
        headers: {
          uid,
          client,
          "access-token": accessToken,
          "token-type": "Bearer",
        },
      },
    );

    return response.data;
  }

  async sign_up(
    name: string,
    email: string,
    password: string,
    password_confirmation: string,
  ): Promise<ISignUpResposne> {
    const response = await this.restClient.post("/api/v1/companies/auth", {
      name,
      email,
      password,
      password_confirmation,
      confirm_success_url: `${process.env.REACT_APP_ADMIN_URL}/sign_in`,
    });

    return response.data;
  }

  async reset_password(email: string) {
    const response = await this.restClient.post(
      "/api/v1/companies/auth/password",
      {
        email,
        redirect_url: `${process.env.REACT_APP_ADMIN_URL}/reset_password/confirmation`,
      },
    );

    return response.data;
  }

  async reset_password_confirmation(reset_password_token: string) {
    const response = await this.restClient.get(
      "/api/v1/companies/auth/password/edit",
      {
        params: {
          reset_password_token,
          redirect_url: `${process.env.REACT_APP_ADMIN_URL}/reset_password/edit`,
        },
      },
    );

    return response.data;
  }

  async update_password(
    password: string,
    password_confirmation: string,
    uid: string,
    client: string,
    accessToken: string,
  ) {
    const response = await this.restClient.put(
      "/api/v1/companies/auth/password",
      {
        password,
        password_confirmation,
      },
      {
        headers: {
          uid,
          client,
          "access-token": accessToken,
        },
      },
    );

    return response.data;
  }

  async resend_confirmation_email(email: string) {
    const response = await this.restClient.post(
      "/api/v1/companies/auth/confirmation",
      {
        email,
        redirect_url: `${process.env.REACT_APP_ADMIN_URL}/sign_in`,
      },
    );

    return response.data;
  }
}

export default AuthClient;
